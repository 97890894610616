<script setup lang="ts">
import { cn } from '@laam/lib/utils';
import type { HTMLAttributes } from 'vue';

const props = defineProps<{
	class?: HTMLAttributes['class'];
}>();
</script>

<template>
	<div
		:class="
			cn(
				'flex flex-col sm:items-center items-start lg:border-b border-gray-200 p-3xl',
				props.class,
			)
		"
	>
		<slot />
	</div>
</template>
